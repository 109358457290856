import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentComponent } from './components/layout/content/content.component';
import { AdminGuard } from './shared/services/admin.guard';

const routes: Routes = [
  //EL ORDEN SI IMPORTA..!!!!!
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./components/pages/login/login.module').then(m => m.LoginModule),
    data: {
      breadcrumb: 'Inicio de sesión'
    }
  },

  //Registro de usuarios
  {
    path: 'register',
    loadChildren: () => import('./components/pages/register/register.module').then(m => m.RegisterModule), data: { breadcrumb: 'Registro de Usuarios' }
  },

  {
    path: 'register-productor',
    loadChildren: () => import('./components/pages/register-productor/register-productor.module').then(m => m.RegisterProductorModule),
    data: {
      breadcrumb: 'Registro de Productores'
    }
  },
  //Registro Tostadores
  {
    path: 'register-tostador',
    loadChildren: () => import('./components/pages/register-tostador/register-tostador.module').then(m => m.RegisterTostadorModule),
    data: {
      breadcrumb: 'Registro de Tostadores'
    }
  },
  {
    path: '',
    component: ContentComponent,
    canActivate: [AdminGuard], //con esta línea me activa el guard para todo. 
    children: [
      {
        path: 'about',
        loadChildren: () => import('./components/pages/about/about.module').then(m => m.AboutModule),
        data: {
          breadcrumb: 'About'
        }
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./components/pages/dashboard/dashboard.module').then(m => m.DashBoardModule),
        data: {
          breadcrumb: 'Listar lote'
        }
      },
      {
        path: 'lotes',
        loadChildren: () => import('./components/pages/lotes/lotes.module').then(m => m.LotesModule),
        data: {
          breadcrumb: 'lotes'
        }
      },
      {
        path: 'publish-lote',
        loadChildren: () => import('./components/pages/publish-lote/publish-lote.module').then(m => m.PublishLoteModule),
        data: {
          breadcrumb: 'Publicar lote'
        }
      },
      {
        path: 'orders',
        loadChildren: () => import('./components/pages/orders/orders.module').then(m => m.OrdersModule),
        data: {
          breadcrumb: 'Ordenes'
        }
      },
      {
        path: 'samples',
        loadChildren: () => import('./components/pages/samples/samples.module').then(m => m.SamplesModule),
        data: {
          breadcrumb: 'Ordenes'
        }
      },
      {
        path: 'perfil',
        loadChildren: () => import('./components/pages/perfil/perfil.module').then(m => m.PerfilModule),
        data: {
          breadcrumb: 'Perfil'
        }
      },
      {
        path: 'contact',
        loadChildren: () => import('./components/pages/contact/contact.module').then(m => m.ContactModule),
        data: {
          breadcrumb: 'Perfil'
        }
      },

      {
        path: 'event',
        loadChildren: () => import('./components/pages/event-details/event-details.module').then(m => m.EventDetailsModule),
        data: {
          breadcrumb: 'Perfil'
        }
      },
      {
        path: 'faqs',
        loadChildren: () => import('./components/pages/faqs/faqs.module').then(m => m.FaqsModule),
        data: {
          breadcrumb: 'Perfil'
        }
      },
      {
        path: 'schedules',
        loadChildren: () => import('./components/pages/schedules/schedules.module').then(m => m.SchedulesModule),
        data: {
          breadcrumb: 'Perfil'
        }
      },
      {
        path: 'about',
        loadChildren: () => import('./components/pages/about/about.module').then(m => m.AboutModule),
        data: {
          breadcrumb: 'Perfil'
        }
      },
      {
        path: 'speaker-details',
        loadChildren: () => import('./components/pages/speaker-details/speaker-details.module').then(m => m.SpeakerDetailsModule),
        data: {
          breadcrumb: 'Perfil'
        }
      },


    ]
  },
  {
    path: '**',
    redirectTo: ''
  },


  // {
  //   path: 'about',
  //   loadChildren: () => import('./components/pages/about/about.module').then(m => m.AboutModule),
  //   data: {
  //     breadcrumb: 'About'
  //   }
  // },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
