<header class="header-area header-area-v2">
    <div class="header-navigation" id="sticky-header" style=" background: #F80044; ">
        <div class="container">
            <div class="nav-container d-flex align-items-center justify-content-between" [ngClass]="visible ? '' : 'breakpoint-on'" (window:resize)="detectHeader()">
                <!-- site logo -->
                <div class="brand_logo">
                    <a href="https://agrodirectcafe.com/{{idiomaActual}}"><img src="assets/images/agrodrirect-logo-blanco_0.png" class="img-fluid main-logo" alt=""></a>
                    <a href="https://agrodirectcafe.com/{{idiomaActual}}"><img src="assets/images/agrodrirect-logo-blanco_0.png" class="img-fluid sticky-logo" alt=""></a>
                </div>
                <div class="nav-menu" [ngClass]="navMethod ? '' : 'menu-on'">
                    <!-- Navbar Close Icon -->
                    <div class="navbar-close" (click)="toggleNav()">
                        <div class="cross-wrap"><span class="top"></span><span class="bottom"></span></div>
                    </div>
                    <!-- nav-menu -->
                    <nav class="main-menu">

                        <ul>
                            <li class="menu-item">
                                <a routerLink="/dashboard">{{ 'menu.panel' | translate }}</a>
                            </li>
                            <li class="menu-item">
                                <a routerLink="/orders">{{ 'menu.mispedidos' | translate }}</a>
                                <li class="menu-item">
                                    <a routerLink="/samples">{{ 'menu.mismuestras' | translate }}</a>
                                </li>
                                <li class="menu-item" *ngIf="tusuario=='P'">
                                    <a routerLink="/lotes/list">{{ 'menu.mislotes' | translate }}</a>
                                </li>
                                <li class="menu-item">
                                    <a routerLink="/perfil">{{ 'menu.miperfil' | translate }}</a>
                                </li>

                        </ul>




                        <!-- <ul>
                            <li class="menu-item" [ngClass]="item.child == true ? 'menu-item-has-children' : ''" *ngFor="let item of navigation">
                                <a href="javascript:void(0)" (click)="trigger(item)" *ngIf="item.child">{{item.linkText}}</a>
                                <a routerLink="{{item.link}}" *ngIf="!item.child">{{item.linkText}}</a>
                                <ul class="sub-menu" *ngIf="item.child" [ngClass]="item.open ? 'd-block' : ''">
                                    <li class="menu-item" [ngClass]="item.child == true ? 'menu-item-has-children' : ''" *ngFor="let item of item.submenu">
                                        <a href="javascript:void(0)" (click)="trigger(item)" *ngIf="item.child">{{item.linkText}}</a>
                                        <a routerLink="{{item.link}}" *ngIf="!item.child">{{item.linkText}}</a>
                                        <ul class="sub-menu" *ngIf="item.child" [ngClass]="item.open ? 'd-block' : ''">
                                            <li class="menu-item" *ngFor="let item of item.submenu">
                                                <a routerLink="{{item.link}}">{{item.linkText}}</a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul> -->
                    </nav>
                    <div class="nav-pushed-item">
                        <div class="navbar-btn dark">
                            <span><i class="fal fa-user"></i> {{usuario}}</span>
                        </div>
                        <div class="navbar-btn dark">
                            <a href="javascript:;" (click)="salir()"><i class="fal fa-sign-out"></i> Salir</a>
                        </div>
                    </div>
                    <!-- <div class="nav-pushed-item">
                        <div class="navbar-btn">
                            <a class="main-btn" routerLink="/event"><i class="fal fa-ticket"></i> Buy Ticket</a>
                        </div>
                    </div> -->
                </div>
                <div class="nav-push-item" [ngClass]="visible ? '' : 'd-none'" (window:resize)="detectHeader()">
                    <div class="navbar-btn light">
                        <span><i class="fal fa-user"></i> {{usuario}} <a (click)="language('en')" ><img class="flag" src="assets/flag/en_US.png" height="20px"></a> <a (click)="language('es')" ><img class="flag" src="assets/flag/es_CO.png" height="20px"></a></span>
                    </div>
                    <div class="navbar-btn light">
                        <a href="javascript:;" (click)="salir()"><i class="fal fa-sign-out"></i> {{ 'menu.salir' | translate }}</a>
                    </div>
                </div>


                <!-- Navbar Toggler -->
                <div class="navbar-toggler" [ngClass]="navMethod ? '' : 'active'" (click)="toggleNav()">
                    <span></span><span></span><span></span>
                </div>
            </div>
        </div>
    </div>
</header>