import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { DataService } from '../services/data.service';
import { Location } from '@angular/common'


@Injectable({
    providedIn: 'root'
})
export class AdminGuard implements CanActivate {
    constructor(
        public authService: DataService,
        public router: Router,
        private location: Location,
        private route: ActivatedRoute
    ) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        //POR RAFAEL
        // console.log('Dentro del Guard /shared/services/admin.guard.ts')
        // Guard for user is login or not
        const user = JSON.parse(localStorage.getItem('user'));




        if (!user || user === null) {
            // console.log('No esta logueado!')
            this.router.navigate(['/login']);
            return true
        }
        else if (user) {
            // console.log('Si esta logueado')
            if (!Object.keys(user).length) {
                this.router.navigate(['/login']);
                return true
            }
        }
        //Validar que el usuario que si esta logueado haya completado su registro
        this.authService.datosUser(user.id).subscribe(data => {
            if (!data['registro']) {
                //NO TIENE REGISTRO
                this.location.back()
                return false;
            }
        });
        return true
    }
}
