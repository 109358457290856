import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import navigation from '../../../data/navigation.json';
import { Router } from '@angular/router';
import { LocalizationService } from '../../../internationalization/localization.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-header-two',
  templateUrl: './header-two.component.html',
  styleUrls: ['./header-two.component.css']
})
export class HeaderTwoComponent implements OnInit {


  public usuario: any;
  public tusuario: any;
  public idiomaActual: any;

  constructor(
    @Inject(DOCUMENT) document: any,
    public router: Router,
    private localizationService: LocalizationService,
    private translate: TranslateService,
  ) {

  }

  public navigation = navigation;


  // Navigation
  navMethod: boolean = true;
  toggleNav() {
    this.navMethod = !this.navMethod;
  }

  // Mobile 
  open: boolean = false;


  trigger(item: { open: boolean; }) {
    item.open = !item.open;
  }


  // Sticky Header
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(_e: any) {
    if (window.pageYOffset > 110) {
      let element = <HTMLElement>document.getElementById('sticky-header');
      element.classList.add('sticky');
    } else {
      let element = <HTMLElement>document.getElementById('sticky-header');
      element.classList.remove('sticky');
    }
  }
  // Add class on resize and onload window
  visible: boolean = false;
  breakpoint: number = 991;
  public innerWidth: any;
  detectHeader() {
    this.innerWidth = window.innerWidth;
    this.visible = this.innerWidth >= this.breakpoint;
  }

  ngOnInit(): void {
    /*
    {"id":"91","email":"vijumx@gmail.com","nombre":"vicente Juarez","tipoProductor":"","tipoUsuario":"T","activo":"1","token":"5bab200e9737770b00e751bf42a995307f7513856159a5eba75cdb6356ea"}
    */
    this.detectHeader();
    const user = JSON.parse(localStorage.getItem('user'));
    this.usuario = user.nombre;
    this.tusuario = user.tipoUsuario;
    this.idiomaActual = localStorage.getItem('language');
  }


  salir() {
    localStorage.removeItem('user');
    localStorage.clear();
    this.router.navigate(['/login']);
    return;

  }


  language(lang: string): void {
    localStorage.setItem('language', lang);
    this.localizationService.initService();
    this.translate.use('idioma:' + lang)
    this.idiomaActual = localStorage.getItem('language');
  }


}
