<footer [class]="layout" [style.background-image]="'url('+ bg +')'">
    <div class="ovent-overlay"></div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8">
                <div class="footer-content-area text-center">
                    <div class="content-box">
                        <a routerLink="/"><img [src]="logo" alt="img" width="200px"></a>
                        <p>{{ 'dashboard.descripcion' | translate }}</p>
                        <ul *ngIf="('banner.idioma' | translate)  == 'es'">
                            <li><a href="https://agrodirectcafe.com/{{ 'banner.idioma' | translate }}"> AgroDirectCafe - Home</a></li>
                            <li><a href="https://agrodirectcafe.com/es/nuestros-servicios">Nuestros Servicios</a></li>
                            <li><a href="https://agrodirectcafe.com/es/nosotros">Nosotros</a></li>
                        </ul>
                        <ul *ngIf="('banner.idioma' | translate)  == 'en'">
                            <li><a href="https://agrodirectcafe.com/{{ 'banner.idioma' | translate }}"> AgroDirectCafe - Home</a></li>
                            <li><a href="https://agrodirectcafe.com/en/our-services">Our Services</a></li>
                            <li><a href="https://agrodirectcafe.com/en/aboutus">About us</a></li>
                        </ul>
                    </div>
                    <div class="social-box">
                        <ul>
                            <li><a href="https://www.facebook.com/agrodirectcafee"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="https://www.linkedin.com/company/agrodirectcafe"><i class="fab fa-linkedin"></i></a></li>
                            <li><a href="https://www.instagram.com/agrodirectcafe/"><i class="fab fa-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<app-back-to-top></app-back-to-top>