import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class ContentComponent implements OnInit {

  // Footer style
  classname = "footer-area footer-area-v1 footer-area-v3  bg_cover";
  ftlogo = "assets/images/agrodrirect-logo-blanco_0.png";
  ftbg = "assets/images/footer-3.jpeg";

  constructor() { }

  ngOnInit(): void {
  }

}
