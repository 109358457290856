import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(
    private translate: TranslateService,

  ) { }
  @Input() layout: number | string | undefined;
  @Input() logo: number | string | undefined;
  @Input() bg: number | string | undefined;

  public idiomaActual: any;

  ngOnInit(): void {
    this.idiomaActual = localStorage.getItem('language');
  }

}
