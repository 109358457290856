<app-preloader></app-preloader>
<router-outlet (activate)="onActivate($event)"></router-outlet>
<!-- 
<h1>{{ 'banner.hello' | translate }} {{ name }}!</h1>


<hr>
<label>{{'banner.change_lang_label' | translate}}:</label>
<br><br>
<select name="language" [(ngModel)]='language' (ngModelChange)="onSelect($event)">
  <option value="en-US">English</option>
  <option value="es-CO">ESPAÑOL</option>
</select> -->