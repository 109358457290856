import { Injectable, NgZone, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { Md5 } from 'ts-md5/dist/Md5';




@Injectable({
  providedIn: 'root'
})
export class DataService implements OnInit {

  private REST_API_SERVER = environment.apis;

  constructor(public httpClient: HttpClient,
    public router: Router,
    public ngZone: NgZone) { }


  ngOnInit(): void { }


  public getAllCountries() {
    return this.httpClient.get(this.REST_API_SERVER + "paises/lPaises");
  }

  /**
   * Crear Usuario
   * @param data 
   * @returns 
   */
  public createUser(data: any) {
    const md5 = new Md5();
    let encrypt = md5.appendStr(data.password).end();
    // console.log(data);
    return this.httpClient.post(this.REST_API_SERVER + "registros/cUsuario", { "nombre": data.nombre, "email": data.email, "password": encrypt, "perfil": data.perfil, "otro": data.otro, "tipoNegocio": data.tipoNegocio, "aceptaCondiciones": data.aceptaCondiciones, "lang": data.lang });
  }

  /**
   * Loguea al usuario
   * @param data 
   * @returns 
   */
  public login(data: any) {
    const md5 = new Md5();
    let encrypt = md5.appendStr(data.password).end();
    // console.log(data);
    return this.httpClient.post(this.REST_API_SERVER + "registros/login", { "email": data.email, "password": encrypt });
  }

  //Consulta todos los datos del usuario
  public datosUser(userId: any) {
    return this.httpClient.post(this.REST_API_SERVER + "registros/datosUser", { "userId": userId });
  }

  /**
   * Olvido Clave
   * @param data 
   * @returns 
   */
  public forget(data: any) {
    return this.httpClient.post(this.REST_API_SERVER + "registros/forget", { "email": data.email, "lang": data.lang });
  }

  /**
   * Reset clave
   * @param data 
   * @returns 
   */
  public reset(data: any) {
    const md5 = new Md5();
    let encrypt = md5.appendStr(data.password).end();
    return this.httpClient.post(this.REST_API_SERVER + "registros/reset", { "token": data.token, "password": encrypt, "clave": data.password });
  }
  //Obtiene datos de trm y carga.... se pueden obtener más variables.
  public config() {
    return this.httpClient.post(this.REST_API_SERVER + "registros/config", '');
  }
  //Obtiene datos del resumen de la cuenta.
  public resumen(idUser) {
    return this.httpClient.get(this.REST_API_SERVER + "registros/resumen/" + idUser);
  }
  //Borrar cuenta.
  public deleteAccount(data) {
    return this.httpClient.post(this.REST_API_SERVER + "registros/deleteAccount", data);
  }

}
